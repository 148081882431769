import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "styled-bootstrap-grid";

import MainLayout from "layouts/MainLayout";

const Wrapper = styled.main``;

const Content = styled.article`
  padding: 90px 0;

  & * {
    font-size: 16px;
    line-height: 1.35;
  }

  & h1 {
    font-size: 28px;
    margin: 60px 0;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & h2 {
    font-size: 20px;
    margin: 30px 0;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & h3 {
    font-size: 16px;
    margin: 20px 0;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & p {
    margin: 20px 0;
    font-size: 16px;
    line-height: 1.35;
  }

  & ol li,
  & ul li {
    margin: 15px 0;
  }
`;

const PolicyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "/strona-glowna/", idType: URI) {
          PageIndex {
            bannerBackgroundColor
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      themeSecondaryColor={data.wpgraphql.page.bannerBackgroundColor}
    >
      <Wrapper>
        <Container>
          <Content>
            <h1>Polityka Prywatności oraz Polityka „Cookies”</h1>
            <h2>I. Polityka Prywatności</h2>
            <h2>Ochrona Danych Osobowych</h2>
            <p>
              Niniejszy dokument określa zasady dotyczące przetwarzania danych
              osobowych osób odwiedzających stronę internetową
              www.larssonnobel.pl (zwanych dalej „Użytkownikami”) w związku z
              korzystaniem przez nich z Serwisu Internetowego Larsson Nobel Sp.
              z o.o.
            </p>
            <ol>
              <li>
                1. Dane osobowe przekazywane przez użytkowników Serwisu
                Internetowego Larsson Nobel Sp. z o.o. (zwaną dalej „Larsson
                Nobel”) za pomocą formularzy elektronicznych są przez Larsson
                Nobel traktowane ze szczególną starannością zgodnie z
                obowiązującymi przepisami prawa, w szczególności: <br />
                a. Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679
                z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
                związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                (Ogólne Rozporządzenie o ochronie danych, dalej „RODO”); <br />
                b. Ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą
                elektroniczną; <br />
                c. Ustawą z dnia 16 lipca 2004 r. Prawo telekomunikacyjne.
              </li>
              <li>
                2. Administratorem danych osobowych osób korzystających ze
                strony www.larssonnobel.pl jest Larsson Nobel Sp. z o.o., ul.
                Jana Ostroroga 29/105, 01-163 Warszawa, NIP: 8513228942, REGON:
                381099138, KRS: 0000746156, Sąd Rejonowy Szczecin-Centrum w
                Szczecinie, XIII Wydział Gospodarczy Krajowego Rejestru
                Sądowego. Kapitał zakładowy 50.000 zł opłacony w całości.
                Kontakt e-mail: iod@larssonnobel.pl dalej jako Larsson Nobel lub
                Administrator danych.
              </li>
              <li>
                3. Dane osobowe mogą być przetwarzane w następujących celach:{" "}
                <br />
                a. nawiązania kontaktu i udzielenia odpowiedzi na przesłanie
                zapytanie za pomocą formularza kontaktowego lub bezpośrednio na
                podany adres e-mail – podstawa prawna przetwarzania – art. 6
                ust. 1 lit. f) RODO, tj. prawnie uzasadniony interes
                Administratora danych, który w tym przypadku stanowi
                nawiązywanie kontaktów handlowych i udzielanie odpowiedzi na
                pytania dotyczące produktów i usług Larsson Nobel;
                <br /> b. przesyłania drogą elektroniczną oraz przekazywania
                poprzez połączenia telefoniczne, informacji o produktach i
                usługach Larsson Nobel, wyłącznie w przypadku wyrażenia zgody
                stosowanie do wymagań art. 172 ust. 1 ustawy z dnia 16 lipca
                2004 r. Prawo telekomunikacyjne (t.j. Dz. U. z 2021 r. poz.576)
                oraz art. 10 ust. 2 ustawy z dnia 18 lipca 2002 r. o świadczeniu
                usług drogą elektroniczną (t.j. Dz. U. z 2020 r. poz. 344.),
                poprzez zaznaczenie pola „checkbox” pod formularzem kontaktowym
                – podstawa prawna przetwarzania – art. 6 ust. 1 lit. f) RODO,
                tj. prawnie uzasadniony interes Administratora danych; <br />
                c. do skorzystania z oferty Larsson Nobel i wykonania czynności
                poprzedzających zawarcie umowy oraz zawarcia umowy na
                dostarczenie produktów i usług – podstawa prawna przetwarzania –
                art. 6 ust. 1 lit. b) RODO w przypadku osoby fizycznej
                zawierającej umowę we własnym imieniu lub art. 6 ust. 1 lit. f)
                RODO w przypadku pełnomocników i osób reprezentujących
                przedsiębiorstwo;prowadzenia korespondencji związanej z
                udzielaniem odpowiedzi na pytania lub w związku z reklamacjami –
                podstawa prawna przetwarzania art. 6 ust. 1 lit. f) RODO; e.
                wypełnienia obowiązków prawnych ciążących na Administratorze
                danych w związku rozliczeniami i obowiązkami podatkowymi –
                podstawa prawna przetwarzania danych – art. 6 ust. 1 lit. c RODO
                w związku z zapisami Ustawy o rachunkowości z dnia 29 września
                1994 roku (Dz.U. z 1994 r. Nr 121, poz. 591 z późn. zm.); f.
                ustalenia, dochodzenia lub obrony ewentualnych roszczeń, które
                mogłyby pojawić się wyniku realizacji usług – podstawa prawna
                przetwarzania – art. 6 ust. 1 lit. f) RODO, tj. prawnie
                uzasadniony interes Administratora danych
              </li>
              <li>
                4. Dane osobowe przetwarzają wyłącznie osoby upoważnione albo
                przetwarzający, z którymi podpisane zostały umowy powierzenia.
              </li>
              <li>
                5. Administrator zapewnia odpowiednie środki techniczne i
                organizacyjne zapewniające bezpieczeństwo danych osobowych
                Użytkowników, w szczególności uniemożliwiające dostęp do
                nichosobomtrzecimlubich przetwarzanie
              </li>
            </ol>
            <h3>
              1. Jakie informacje zbierane są przez Larsson Nobel za
              pośrednictwem Strony?
            </h3>
            <ol>
              <li>
                1. Proces przetwarzania danych osobowych Użytkowników
                korzystających ze Strony obejmuje w szczególności: a. zbieranie
                danych osobowych z formularza wypełnianego na Stronie
                dobrowolnie przez Użytkownika w celu przedstawienia oferty
                produktowej. b. gromadzenie danych dotyczących wizyty
                Użytkowników na stronie www.larssonnobel.pl w tym danych takich
                jak: numer IP, aktywność na stronie i przybliżona lokalizacja
                geograficzna Użytkownika.
              </li>
              <li>
                Użytkownik rejestrujący formularz kontaktowy przekazuje za
                pomocą strony www.larssonnobel.pl następujące dane osobowe:{" "}
                <br />
                a. Imię i nazwisko; <br />
                b. Numer telefonu; <br />
                c. Adres e-mail; <br />
                d. dane podane w treści wiadomości.
              </li>
              <li>
                Larsson Nobel będzie przetwarzać dane osobowe nie dłużej niż
                jest to koniecznie dla udzielenia odpowiedzi na dane zapytanie
                wysłane przez formularz kontaktowy oraz dla ewentualnego
                zawarcia umowy na dostarczenie produktów i usług, a także gdy
                jest to zgodne z obowiązującymi przepisami prawa.
              </li>
            </ol>
            <h3>
              2. W jaki sposób Larsson Nobel wykorzystuje dane udostępnione
              przez Użytkowników za pośrednictwem Strony?
            </h3>
            <ol>
              <li>
                Dane udostępnione przez Użytkownika za pośrednictwem Strony mogą
                zostać wykorzystane przez Larsson Nobel w celu: a. świadczenia
                usług drogą elektroniczną, polegających na wysłaniu zapytania
                przez formularz kontaktowy dostępny na stronie internetowej i
                obsługi ww. zapytania poprzez udzielenie odpowiedzi; b.
                marketingu bezpośredniego własnych produktów i usług Larsson
                Nobel w zakresie wykraczającym poza jednorazowe udzielenie
                odpowiedzi na pytanie zawarte w formularzu kontaktowym lub
                przesłane bezpośrednio na podany adres e-mail, jednakże tylko w
                przypadku wyrażenia zgody na marketing bezpośredni realizowany
                drogą elektroniczną i telefoniczną przy wykorzystaniu
                telekomunikacyjnych urządzeń końcowych.
              </li>
              <li>
                Podmiotami, które oprócz Larsson Nobel będą mogły mieć dostęp do
                danych Użytkownika są:
                <br /> a. podmioty świadczące usługi IT na rzecz Larsson Nobel,
                w tym podmioty obsługujące systemy teleinformatyczne lub
                udostępniające narzędzia teleinformatyczne, z zachowaniem
                wszelkich gwarancji zapewniających bezpieczeństwo przekazywanych
                danych osobowych. <br />
                b. podmioty działające w otoczeniu biznesowym, powiązanym lub
                współpracującym z Larsson Nobel, do celów związanych z
                realizacją procesów biznesowych (w szczególności np. w celu
                wykonywania i zawierania umów oraz obsługi roszczeń) <br />
                c. podmioty, którym udostępnienie danych nastąpiło na podstawie
                odrębnie udzielonych przez Użytkownika zgód.
              </li>
            </ol>
            <h3>
              3. Jakie uprawnienia posiada Użytkownik strony
              www.larssonnobel.pl?
            </h3>
            <ol>
              <li>
                Każda osoba, której dane dotyczą, ma prawo:
                <br /> a. dostępu do treści swoich danych <br />
                b. sprostowania danych, gdy są one nieprawidłowe,
                <br />
                c. żądania usunięcia danych, <br />
                d. żądania ograniczenia przetwarzania danych, <br />
                e. prawo do przenoszenia danych, <br />
                f. wniesienia sprzeciwu wobec przetwarzania danych
                <br />
                g. wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych
                (PUODO ul. Stawki 2, 00-193 Warszawa) wobec przetwarzania przez
                Larsson Nobel sp. z o.o. danych osobowych niezgodnie z
                obowiązującym prawem.
              </li>
              <li>
                Użytkownik, który nie życzy sobie otrzymywać wiadomości
                zawierających informacje handlowe od Larsson Nobel ma możliwość
                rezygnacji z ich otrzymywania. Rezygnacje z otrzymywania
                materiałów marketingowych na pocztę elektroniczną należy
                przekazać do centrali Larsson Nobel za pomocą poczty
                elektronicznej (e-mail: iod@larssonnobel.pl). Po otrzymaniu
                informacji o wycofaniu zgody, Larsson Nobel usunie dane
                Użytkownika z list mailingowych.
              </li>
              <li>
                Dane osobowe użytkownika strony internetowej nie będą
                przetwarzane w sposób zautomatyzowany, w tym w formie
                profilowania oraz nie będą przekazywane poza terytorium
                Europejskiego Obszaru Gospodarczego.
              </li>
            </ol>
            <h3>4. Informacje dodatkowe dotyczące polityki prywatności</h3>
            <ol>
              <li>
                1. Użytkownikiem Strony, który przekazuje Larsson Nobel dane
                osobowe poprzez formularz wypełniany bezpośrednio przez
                Użytkownika lub informacje podane w inny sposób (z wyłączeniem
                plików cookie i logów serwera) może być osoba, która ukończyła
                18 lat.
              </li>
              <li>
                2. Niniejsza Polityka prywatności może ulegać zmianom. Wraz z
                każdą zmianą, nowa wersja Polityki Prywatności będzie się
                pojawiać z nową datą. Wszelkie najistotniejsze z punktu widzenia
                Użytkownika zmiany będą odpowiednio wyszczególnione w Serwisie.
                Aktualna wersja Polityki Prywatności wraz z datą ostatniej
                aktualizacji jest zawsze dostępna na stronie www.larssonnobel.pl
              </li>
              <li>
                3. Kontakt z osobą nadzorującą przetwarzanie danych osobowych
                jest możliwy drogą elektroniczną pod adresem e-mail:
                iod@larssonnobel.pl
              </li>
            </ol>
            <h2>II.Polityka „cookies”</h2>
            <h3>1. Czym są pliki „cookies”?</h3>
            <p>
              Ciasteczka (ang. cookies) – małe pliki tekstowe, tworzone
              automatycznie przez przeglądarkę internetową, podczas odwiedzania
              stron WWW i zapisywane po stronie użytkownika. Informacje zawarte
              w danym pliku cookie mogą być odczytane wyłącznie przez stronę, z
              której ten plik pochodzi.
            </p>
            <h3>2. Jakie funkcje pełnią pliki „cookies”?</h3>
            <p>
              Pliki „cookies” pełnią funkcje zapamiętywania preferencji
              użytkownika i dostosowywania do nich zawartości stron
              internetowych, umożliwiania przygotowania statystyk odwiedzin
              stron internetowych lub możliwość polecania użytkownikowi treści,
              które są dla niego najbardziej odpowiednie.
            </p>
            <h3>3. Do czego używamy plików „cookies”?</h3>
            <p>
              Cookies to istotny element działania naszej strony internetowej.
              Dzięki nim tworzone są statystyki, które pomagają nam zrozumieć, w
              jaki sposób użytkownicy serwisu korzystają ze stron internetowych,
              co umożliwia ulepszanie ich struktury i zawartości. Ponadto dzięki
              „cookies” możliwe jest utrzymanie sesji Użytkownika Serwisu (po
              zalogowaniu), dzięki której Użytkownik nie musi na każdej
              podstronie Serwisu ponownie wpisywać loginu i hasła. <br />
              <br />
              Pliki „cookies” służą nam także do określania profilu użytkownika
              w celu wyświetlania mu dopasowanych materiałów w sieciach
              reklamowych. Pliki „cookies”, które używa nasza strona
              internetowa, nie wyrządzają żadnych szkód i nie powodują zmian w
              urządzeniu użytkownika. Pliki „cookies” nie zawierają danych
              identyfikacyjnych tj. nazwisk, adresów czy informacji o
              płatnościach. Ponadto, nie mogą być wykorzystywane do
              rozpowszechniania wirusów i każdy użytkownik ma możliwość
              samodzielnego zarządzania plikami „cookies”. Umożliwiają to np.
              przeglądarki internetowe, z których korzystamy.
            </p>
            <h3>4. Usuwanie plików "cookies"</h3>
            <p>
              Każdy rodzaj przeglądarki oferuje możliwość blokowania i kasowania
              plików „cookies”. Więcej informacji na temat zarządzania plikami
              „cookies” można uzyskać, korzystając z poniższych linków: Internet
              Explorer Firefox Google Chrome Safari
            </p>
            <h3>5. Cookies od zewnętrznych serwisów</h3>
            <p>
              Na naszej stronie internetowej znajdują się pliki „cookies” od
              serwisów partnerskich, które podlegają ich własnej polityce
              prywatności.
              <br />
              <br /> Google Analitics:
              <br />
              http://www.google.pl/policies/privacy/key-terms/ -
              toc-terms-identifier <br />
              <br />
              LinkedIN:
              <br />
              https://www.linkedin.com/legal/cookie-policy?trk=organization-guest_footer-cookie-policy
              <br />
              <br />
              Facebook:
              <br /> http://www.facebook.com/about/privacy/cookies
              <br /> <br />
              Aby dowiedzieć się więcej o plikach cookie oraz o tym, jak
              ograniczyć lub całkowicie zablokować pliki „cookies”, prosimy
              odwiedzić adres: www.wszystkoociasteczkach.pl data ostatniej
              aktualizacji: 11.05.2021 r.
            </p>
          </Content>
        </Container>
      </Wrapper>
    </MainLayout>
  );
};

export default PolicyPage;
